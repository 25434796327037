import React from 'react';
import App from './App';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { store } from './redux';
import { HelmetProvider } from 'react-helmet-async';
import Hotjar from '@hotjar/browser';

const root = ReactDOM.createRoot(document.getElementById('root'));
const { REACT_APP_HOTJARID, REACT_APP_HOTJARVERSION } = process.env;

const siteId = REACT_APP_HOTJARID;
const hotjarVersion = REACT_APP_HOTJARVERSION;
Hotjar.init(siteId, hotjarVersion);

root.render(
    <HelmetProvider>
        <Provider store={store}>
            <BrowserRouter>
                <App />
            </BrowserRouter>
        </Provider>
    </HelmetProvider>
);
